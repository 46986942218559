import React, { useEffect, useState } from 'react';
import {
    Card,
    Col,
    Row,
    Tooltip,
    Button,
    Divider,
    Space,
    Flex,
    Segmented,
    message,
    Spin
} from "antd";
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyAuthCodeShop } from '../../services/apis';


const AuthenTiktokShopPage = () => {
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const [mess, setMess] = useState("Đang xác thực tài khoản...");
    const navigate = useNavigate();
    const authenTikTokShop = async (auth_code) => {
        setLoading(true);
        const params = {
            auth_code: auth_code
        }
        const res = await verifyAuthCodeShop(params);
        if (res && res.status === 1) {
            message.success("Xác thực tài khoản thành công");
            setMess("Xác thực tài khoản thành công");
            setLoading(false);
            navigate("/config-shop");
        } else {
            message.error("Xác thực tài khoản thất bại");
            setMess("Xác thực tài khoản thất bại");
            setLoading(false);
        }
    }
    
    useEffect(() => {
        const code = searchParams.get('code');
        if (code) {
            authenTikTokShop(code);
          }
    }, [searchParams])
    return (
        <div className="layout-content">
            <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 100 }}>
                    <img src="https://dl.memuplay.com/new_market/img/com.tiktokshop.seller.icon.2023-03-29-00-07-21.png" style={{ width: 200, height: 200, borderRadius: 100 }} />
                    <span style={{textAlign: 'center', fontSize: 20, color: 'black'}}>{mess}</span>
                </div>
            </div>
            
            <LoadingBase loading={loading} />
        </div>

    );
};
export default AuthenTiktokShopPage;