import React, { useEffect, useState } from 'react';
import {
    Button,
    message,
    Spin
} from "antd";
import '../../css/listbook-page.css';
import LoadingBase from '../../components/LoadingBase';
import { getAuthUrlTiktok } from '../../services/apis';
import { consoleLogUtil } from '../../utils/consoleLogUtil';


const VerifyTTSPage = () => {
    const [loading, setLoading] = useState(false);
    const [authUrl, setAuthUrl] = useState("");
    
    const handleClickOpenLinkAuth = () => {
        window.open(authUrl, '_self');
    }

    const getDataUrl = async () => {
        setLoading(true);
        // Call api to get authen url
        const res = await getAuthUrlTiktok();
        consoleLogUtil("res", res);
        if (res && res.status === 1) {
            setAuthUrl(res?.data?.auth_url);
        } else {
            message.error("Lấy url xác thực thất bại");
        }
        setLoading(false);
    }
    useEffect(() => {
        getDataUrl();
    }, [])
    return (
        <div className="layout-content">
            <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 100 }}>
                    <img src="https://dl.memuplay.com/new_market/img/com.tiktokshop.seller.icon.2023-03-29-00-07-21.png" style={{ width: 200, height: 200, borderRadius: 100 }} />
                    <Button type="primary" style={{ marginTop: 10 }} disabled={loading || authUrl.length === 0} onClick={handleClickOpenLinkAuth}>Xác thực tài khoản TTS UK</Button>
                </div>
            </div>
            <LoadingBase loading={loading} />
        </div>

    );
};
export default VerifyTTSPage;