import {
    LogoutOutlined,
    FileSearchOutlined,
    ProjectOutlined,
    ImportOutlined,
    VerifiedOutlined,
    TableOutlined,
    AccountBookOutlined,
    MenuOutlined
} from '@ant-design/icons';

export const DATE_FORMAT = {
    DDMMYYYY_FORMAT: "DD/MM/YYYY",
    YYYYMMDD_FORMAT: "YYYY-MM-DD",
    DDMM_FORMAT: "DD/MM",
    DD_MM_YYYY_FORMAT: "DD-MM-YYYY"
}

export const TYPE_MODAL = {
    ADD: "ADD",
    EDIT: "EDIT",
    DEL: "DEL",
    APPROVE: "APPROVE"
}

export const LIST_MENU_SIDE_BAR = [
    
    
    {
        key: 'config-shop',
        path: "/config-shop",
        label: 'Cấu hình shop',
        icon: <ImportOutlined />,
        permission: [1,3]
    },
    {
        key: 'verify-tts',
        path: "/verify-tts",
        label: 'Verify Tiktok Shop',
        icon: <VerifiedOutlined />,
        permission: [1,3]
    },
    {
        key: 'list-account-tts',
        path: "/list-account-tts",
        label: 'Danh sách Account Tiktok',
        icon: <MenuOutlined />,
        permission: [1,3]
    },
    {
        key: 'list-account-printify',
        path: "/list-account-printify",
        label: 'Danh sách Account Printify',
        icon: <AccountBookOutlined />,
        permission: [1,3]
    },
    {
        label: 'Báo cáo',
        icon: <ProjectOutlined />,
        children: [
            {
                key: 'bao-cao-order',
                path: "/bao-cao-order",
                label: 'Báo cáo đơn hàng',
                icon: <FileSearchOutlined />,
                permission: [1, 2, 3]
            },
            
        ]
    },
    // {
    //     key: 'doi-mat-khau',
    //     path: "/doi-mat-khau",
    //     label: 'Đổi mật khẩu',
    //     icon: <ImportOutlined />,
    //     permission: [1,2,3]
    // },
    {
        key: 'dang-xuat',
        path: "/dang-xuat",
        label: 'Đăng xuất',
        icon: <LogoutOutlined />,
        permission: [1, 2, 3]
    },

];

export const requiredItem = [
    {
        required: true,
        message: 'Vui lòng không để trống'
    }
];


export const FORMAT_DATE = {
    YYYYMMDDHHMMSS: 'YYYY-MM-DD HH:mm:ss',
    YYYMMDD: 'YYYY-MM-DD',
    DDMMYYYY: 'DD/MM/YYYY',
}