import { Button, Col, Form, Row, Space, theme, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import { addAndUpdateAccountPrintify, getListAccountAndShopPrintify } from '../../services/apis';
import ModalAddAccount from './component/ModalAddAccount';

const ListAccountPrintifyPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const columns = [
        {
            title: 'STT',
            width: 50,
            align: 'center',
            render: (text, record, index) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{index + 1}</div>
        },
        {
            title: 'Printify account',
            dataIndex: 'printify_account',
            key: 'printify_account',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Shop ID',
            dataIndex: 'shop_id',
            key: 'shop_id',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Sales channel',
            dataIndex: 'sales_channel',
            key: 'sales_channel',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text) => <div style={{color: text === '1' ? 'blue' : (text === '2' ? 'red' : 'black')}}>{text === '1' ? "Đã kết nối" : (text === '2' ? "Đã huỷ kết nối" : (text === '3' ? '' : "Chưa kết nối"))}</div>
        },
    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    

    const getDataReport = async () => {
        setLoading(true);
        const res = await getListAccountAndShopPrintify();
        consoleLogUtil("res", res);
        setLoading(false);
        if (res && res.status === 1) {
            setData([...res.data || []]);
        } else {
            setData([]);
        }
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        },
      };
    const onFinish = async () => {
       setShowModal(true);
    };
    
    const handleAddAccount = async (data) => {
        setLoading(true);
        const res = await addAndUpdateAccountPrintify(data);
        setLoading(false);
        if (res && res.status === 1) {
            message.success('Thêm mới tài khoản thành công');
            setShowModal(false);
            getDataReport();
        } else {
            message.warning('Thêm mới tài khoản thất bại');
        }
    }
    
    useEffect(() => {
        getDataReport();
    }, []);

    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    
                    <Col span={3}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Thêm mới tài khoản Printify
                            </Button>
                        </Space>
                    </Col>
                    
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                rowSelection={{ ...rowSelection }}
                pagination={false}
                rowKey={(record) => record?.id}
            />
            <ModalAddAccount 
                visible={showModal}
                onCancel={() => setShowModal(false)}
                onSave={handleAddAccount}
            />
            <LoadingBase loading={loading} />
            
        </div>
    )
}
export default React.memo(ListAccountPrintifyPage);