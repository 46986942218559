import { Button, Col, Form, Input, Modal, Row, Space, theme } from "antd"
import React, { useEffect } from "react";

const ModalAddAccount = ({ visible, onCancel, onSave }) => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const handleSave = (data) => {
        if (onSave) {
            onCancel();
            onSave(data);
        }
    }

    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
    }, [visible]);

    return (
        <Modal
            title={"Thêm account printify"}
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={800}
        >
            <Form form={form} name="add_connect" style={formStyle} onFinish={handleSave} layout="vertical">
                
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name={'printify_account'}
                            label={'Printify account'}
                            rules={[{ required: true, message: 'Vui lòng chọn nhập account printify' }]}
                        >
                            <Input placeholder="Printify account"/>
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name={'printify_api_token'}
                            label={'API key token'}
                            rules={[{ required: true, message: 'Vui lòng nhập api key token' }]}
                        >
                            <Input.TextArea placeholder="Access token"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} justify={'end'}>
                    <Space>
                        <Button onClick={onCancel}>
                            Hủy
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Thêm
                        </Button>
                    </Space>
                </Row>

            </Form>
        </Modal>
    )
}
export default React.memo(ModalAddAccount);