import axiosClient from "./axios.client";

export const login = (params) => {
    return axiosClient.post('api/authen/login', params);
}

export const saveLogUser = (params) => {
    return axiosClient.post('logUser',params);
}

export const verifyAuthCodeShop = (params) => {
    return axiosClient.post('api/authen/authenShopTiktok', params);
}

export const getListAccountPrintify = (params) => {
    return axiosClient.post('api/cms/connect/getListAccountPrintify', params);
}

export const getListShopPrintifyAvailableByAccount = (params) => {
    return axiosClient.post('api/cms/connect/getListShopPrintifyAvailableByAccount', params);
}

export const getListAccTiktokShopAvailable = () => {
    return axiosClient.get('api/cms/connect/getListAccTiktokShopAvailable');
}

export const mapShopTiktokWithShopPrintify = (params) => {
    return axiosClient.post('api/cms/connect/mapShopTiktokWithShopPrintify', params);
}

export const getListShopConnected = (params) => {
    return axiosClient.get(`api/cms/connect/getListShopConnected?keyword=${params.keyword}`);
}

export const cancelConnectShop = (params) => {
    return axiosClient.post('api/cms/connect/cancelConnectShop', params);
}

export const getReportSyncOrder = (params) => {
    return axiosClient.post('api/cms/report/getReportSyncOrder', params);
}

export const getAuthUrlTiktok = () => {
    return axiosClient.get('api/cms/config/getAuthUrlTiktok');
}

export const getListAccountAndShopPrintify = () => {
    return axiosClient.get('api/cms/shops/getListAccountAndShopPrintify');
}

export const addAndUpdateAccountPrintify = (params) => {
    return axiosClient.post('api/cms/shops/addAndUpdateAccountPrintify', params);
}

export const getListAccountTiktokShop = (params) => {
    return axiosClient.get(`api/cms/shops/getListAccountTiktokShop?keyword=${params.keyword}`);
}

export const getListDataShopForFilter = () => {
    return axiosClient.get(`api/cms/report/getListDataShopForFilter`);
}