import { Button, Col, Form, Row, Space, theme, Table, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import { getListAccountTiktokShop } from '../../services/apis';

const ListAccountTTSPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: 'STT',
            width: 50,
            align: 'center',
            render: (text, record, index) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{index + 1}</div>
        },
        {
            title: 'TikTok Shop Name',
            dataIndex: 'shop_name',
            key: 'shop_name',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Shop ID',
            dataIndex: 'shop_id',
            key: 'shop_id',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Shop code',
            dataIndex: 'shop_code',
            key: 'shop_code',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text) => <div style={{ color: text === '1' ? 'blue' : (text === '2' ? 'red' : 'black') }}>{text === '1' ? "Đã kết nối" : (text === '2' ? "Đã huỷ kết nối" : "Chưa kết nối")}</div>
        },
    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    

    const getDataReport = async () => {
        setLoading(true);
        const params = {
            keyword: form.getFieldValue('keyword') ?? '',
        }
        const res = await getListAccountTiktokShop(params);
        consoleLogUtil("res", res);
        setLoading(false);
        if (res && res.status === 1) {
            setData([...res.data || []]);
        } else {
            setData([]);
        }
    }

   
    const onFinish = async () => {
        getDataReport();
    };

    

    useEffect(() => {
        getDataReport();
    }, []);

    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Form.Item
                        name="keyword"
                    >
                        <Input placeholder="Tìm kiếm" />
                    </Form.Item>
                    <Col span={3}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>

                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
            />
            
            <LoadingBase loading={loading} />

        </div>
    )
}
export default React.memo(ListAccountTTSPage);