import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import LoginPage from './screens/login/LoginPage';
import NotFound from './components/NotFound';
import PrivateRoute from './components/PrivateRoute';
import AuthenTiktokShopPage from './screens/authen-shop/AuthenTiktokShopPage';
import ConfigMapShopPage from './screens/config-shop/ConfigMapShopPage';
import ReportRefundPage from './screens/report/ReportTransitionOrder';
import VerifyTTSPage from './screens/verify-tts/VerifyTTSPage';
import ListAccountPrintifyPage from './screens/list-account-printify/ListAccountPrintifyPage';
import ListAccountTTSPage from './screens/list-account-tts/ListAccountTTSPage';


const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path="/dang-nhap" element={
                    <LoginPage />
                } />
                <Route path="/authen" element={
                        <AuthenTiktokShopPage />
                } />
                <Route path="/" element={
                        <PrivateRoute>
                            <ConfigMapShopPage />
                        </PrivateRoute>
                } />
                <Route path="/config-shop" element={
                        <PrivateRoute>
                            <ConfigMapShopPage />
                        </PrivateRoute>
                } />
                <Route path="/bao-cao-order" element={
                        <PrivateRoute>
                            <ReportRefundPage />
                        </PrivateRoute>
                } />
                <Route path="/verify-tts" element={
                        <PrivateRoute>
                            <VerifyTTSPage />
                        </PrivateRoute>
                } />
                <Route path="/list-account-printify" element={
                        <PrivateRoute>
                            <ListAccountPrintifyPage />
                        </PrivateRoute>
                } />
                <Route path="/list-account-tts" element={
                        <PrivateRoute>
                            <ListAccountTTSPage />
                        </PrivateRoute>
                } />
            </Routes>

        </BrowserRouter>
    );
};

export default AppRouter;