import { Button, Col, Form, Modal, Row, Select, Space, theme } from "antd"
import React, { useEffect, useState } from "react";
import { filterOption } from "../../../utils/funcHelper";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";
import { getListAccountPrintify, getListAccTiktokShopAvailable, getListShopPrintifyAvailableByAccount } from "../../../services/apis";
import LoadingBase from "../../../components/LoadingBase";
import { set } from "lodash";

const ModalAddConnectShop = ({ visible, onCancel, onSave }) => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();
    const [lstPrintfyShop, setLstPrintfyShop] = useState([]);
    const [lstTikTokShop, setLstTikTokShop] = useState([]);
    const [lstAccountPrintfy, setLstAccountPrintfy] = useState([]);
    const [loading, setLoading] = useState(false);
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const handleSave = (data) => {
        const params = {
            tts_id: data.tiktok_shop,
            printify_shop_id: data.printify_shop,
            tts_name: lstTikTokShop.find(item => item.value === data.tiktok_shop)?.label,
            printify_shop_name: lstPrintfyShop.find(item => item.value === data.printify_shop)?.label
        }
        if (onSave) {
            onCancel();
            onSave(params);
        }
    };

    const handleSelectAccountPrintify = async (value) => {
        setLstPrintfyShop([]);
        form.setFieldsValue({"printify_shop": null});
        getDataShopPrintifyByAccount(value);
    }

    const getDataAccountTiktokShop = async () => {
        try {
            const res = await getListAccTiktokShopAvailable();
            if (res && res.status === 1) {
                const data = res.data || [];
                const lstShopTts = data.map(item => {
                    return {
                        ...item,
                        value: item.shop_id,
                        label: item.shop_name
                    }
                });
                setLstTikTokShop([...lstShopTts]);
            } else {
                setLstTikTokShop([]);
            }
        } catch (error) {
            consoleLogUtil("error", error);
            setLstTikTokShop([]);
        }
    }
    const getDataShopPrintifyByAccount = async (id) => {
        try {
            setLoading(true);
            const params = {
                id: id
            }
            const res = await getListShopPrintifyAvailableByAccount(params);
            setLoading(false);
            if (res && res.status === 1) {
                const data = res.data || [];
                const lstShopPtf = data.map(item => {
                    return {
                        ...item,
                        value: item.id,
                        label: item.title
                    }
                });
                setLstPrintfyShop([...lstShopPtf]);
            } else {
                setLstPrintfyShop([]);
            }
        } catch (error) {
            consoleLogUtil("error", error);
            setLstPrintfyShop([]);
        }
    }

    const getDataAccountPrintify = async () => {
        try {
            const params = {
                keyword: '',
                limit: 100,
                currentPage: 1
            }
            const res = await getListAccountPrintify(params);
            if (res && res.status === 1) {
                const data = res.data?.data || [];
                const lstAccPrintify = data.map(item => {
                    return {
                        ...item,
                        value: item.id,
                        label: item.printify_account
                    }
                });
                setLstAccountPrintfy([...lstAccPrintify]);
            }
        } catch (error) {
            consoleLogUtil("error", error);
        }

    }

    useEffect(() => {
        if (visible) {
            setLstPrintfyShop([]);
            setLstTikTokShop([]);
            setLstAccountPrintfy([]);
            form.resetFields();
            setLoading(true);
            Promise.all([getDataAccountTiktokShop(), getDataAccountPrintify()]).finally(() => setLoading(false));
        }
    }, [visible]);

    return (
        <Modal
            title={"Thêm liên kết shop"}
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={800}
        >
            <Form form={form} name="add_connect" style={formStyle} onFinish={handleSave} layout="vertical">
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name={'acount_printify'}
                            label={'Chọn account printify'}
                            rules={[{ required: true, message: 'Vui lòng chọn account printify' }]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn account printify'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={lstAccountPrintfy}
                                onSelect={handleSelectAccountPrintify}
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name={'printify_shop'}
                            label={'Printify shop'}
                            rules={[{ required: true, message: 'Vui lòng chọn printify shop' }]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn printify shop'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={lstPrintfyShop}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'tiktok_shop'}
                            label={'Tiktok shop'}
                            rules={[{ required: true, message: 'Vui lòng chọn tiktok shop' }]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn tiktok shop'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={lstTikTokShop}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} justify={'end'}>
                    <Space>
                        <Button onClick={onCancel}>
                            Hủy
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Tạo liên kết
                        </Button>
                    </Space>
                </Row>

            </Form>
            <LoadingBase loading={loading} />
        </Modal>
    )
}
export default React.memo(ModalAddConnectShop);