import { Button, Col, Form, Row, Space, theme, Table, message, Popconfirm, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import dayjs from 'dayjs';
import ModalAddConnectShop from './component/ModalAddConnectShop';
import { cancelConnectShop, getListShopConnected, mapShopTiktokWithShopPrintify } from '../../services/apis';

const ConfigMapShopPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const columns = [
        {
            title: 'STT',
            width: 50,
            align: 'center',
            render: (text, record, index) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{index + 1}</div>
        },
        {
            title: 'Tiktok Shop Name',
            dataIndex: 'tts_name',
            key: 'tts_name',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Tiktok Shop ID',
            dataIndex: 'tts_id',
            key: 'tts_id',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Printify Shop Name',
            dataIndex: 'printify_shop_name',
            key: 'printify_shop_name',
            align: 'center',
            render: (text) => <div>{text}</div>
        },
        {
            title: 'Printify Shop ID',
            dataIndex: 'printify_shop_id',
            key: 'printify_shop_id',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12, color: text === 2 ? 'red' : 'blue' }}>{text === 1 ? "Đã kết nối" : "Đã huỷ kết nối"}</div>
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'createAt',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{dayjs(text).format('DD/MM/YYYY HH:mm')}</div>
        },
        {
            title: 'Hành động',
            key: 'action',
            align: 'center',
            render: (text, record) => (
                record?.status === "1" ?
                <Popconfirm
                    title="Huỷ kết nối shop"
                    description="Bạn có chắc chắn muốn huỷ kết nối shop này không?"
                    onConfirm={() => handleDeleteShop(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="link" danger >
                        Huỷ kết nối
                    </Button>
                </Popconfirm>
                : null
            ),
        }

    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    
    const handleDeleteShop = async (record) => {
        try {
            setLoading(true);
            const params = {
                id: record.id
            }
            const res = await cancelConnectShop(params);
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Huỷ kết nối shop thành công');
                getDataReport();
            } else {
                message.warning('Huỷ kết nối shop thất bại');
            }
        } catch (error) {
            consoleLogUtil("error", error);
            setLoading(false);
        }
        
    }

    const getDataReport = async () => {
        setLoading(true);
        const params = {
            keyword: form.getFieldValue('keyword') ?? ''
        }
        const res = await getListShopConnected(params);
        setLoading(false);
        if (res && res.status === 1) {
            setData([...res.data || []]);
        } else {
            setData([]);
        }
    }


    const onFinish = async () => {
        getDataReport();
    };
    

    const handleMakeConnectShop = async (payload) => {
        try {
            setLoading(true);
            const res = await mapShopTiktokWithShopPrintify(payload);
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Kết nối shop' + payload.tts_name + ' với shop ' + payload.printify_shop_name + ' thành công');
            } else {
                message.warning('Kết nối shop' + payload.tts_name + ' với shop ' + payload.printify_shop_name + ' thất bại');
            }
        } catch (error) {
            consoleLogUtil("error", error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getDataReport();
    }, []);

    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'keyword'}
                        >
                            <Input placeholder="Nhập từ khóa" />
                        </Form.Item>
                    </Col>
                    
                    <Col span={3}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                    <Col span={3}>
                        <Space size="small">
                            <Button type="dashed" danger onClick={() => setShowModal(true)}>
                                Thêm liên kết shop
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
            />
            
            <ModalAddConnectShop visible={showModal} onSave={handleMakeConnectShop} onCancel={() => setShowModal(false)}/>
            <LoadingBase loading={loading} />
            
        </div>
    )
}
export default React.memo(ConfigMapShopPage);