import { Button, Col, Form, Row, Space, theme, Table, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import FooterTable from '../../components/FooterTable';
import dayjs from 'dayjs';
import { filterOption } from '../../utils/funcHelper';
import { requiredItem } from '../../constants/constants';
import { getListDataShopForFilter, getReportSyncOrder } from '../../services/apis';

const ReportTransitionOrder = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const { RangePicker } = DatePicker;
    const [totalRecords, setTotalRecords] = useState(0);
    const [dataTTS, setDataTTS] = useState([]);
    const [dataPrintifyShop, setDataPrintifyShop] = useState([]);
    const columns = [
        {
            title: 'STT',
            width: 50,
            align: 'center',
            render: (text, record, index) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{index + 1}</div>
        },
        {
            title: 'TTS Name',
            dataIndex: 'tiktok_shop_name',
            key: 'tiktok_shop_name',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'PRF Store Name',
            dataIndex: 'printify_shop_name',
            key: 'printify_shop_name',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Order ID Tiktok',
            dataIndex: 'tiktok_order_id',
            key: 'tiktok_order_id',
            align: 'center',
            render: (text) => <div>{text}</div>
        },
        {
            title: 'Order ID Printify',
            dataIndex: 'printify_order_id',
            key: 'order_id_printify',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Tracking number',
            dataIndex: 'tracking_number',
            key: 'tracking_number',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12, color: record?.status_tts === 2 ? 'blue' : (record?.status_tts === 3 ? 'red' : null) }}>{renderStatus(record?.status_tts, record?.status_printify)}</div>
        },
        {
            title: 'Time create',
            dataIndex: 'createAt',
            key: 'createAt',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Time update',
            key: 'updateAt',
            render: (text, record) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{record?.tts_updateAt ?? record?.prinf_updateAt}</div>
        },
    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    
    const renderStatus = (tts_status, printify_status) => {
        if (tts_status === 2) {
            return 'Đã update tracking';
        } else if (tts_status === 3) {
            return 'Update tracking thất bại';
        } else if (tts_status === 1 && printify_status === 1) {
            return 'Đã tạo order Printify';
        } else if (tts_status === 1 && printify_status === 3) {
            return 'Tạo order Printify thất bại';
        }
    }

    const getDataReport = async (page, startTime, endTime) => {
        const params = {
            tts_id: form.getFieldValue('tts_id') ?? '',
            printify_shop_id: form.getFieldValue('printify_shop_id') ?? '',
            currentPage: page,
            limit: 10,
            fromTime: startTime,
            toTime: endTime
        }
        setLoading(true);
        const res = await getReportSyncOrder(params);
        setLoading(false);
        if (res && res.status === 1) {
            setData([...res?.data?.data]);
            setTotalRecords(res?.data?.totalRecords);
            setTotalPage(res?.data?.totalPages);
        } else {
            setData([]);
            setTotalRecords(0);
            setTotalPage(0);
        }
    }


    const onFinish = async (dataForm) => {
        setCurrentPage(1);
        if (dataForm && dataForm?.date) {
            getDataReport(1, dayjs(dataForm.date[0]).format("YYYY-MM-DD HH:mm:ss"), dayjs(dataForm.date[1]).format("YYYY-MM-DD HH:mm:ss"))
        } else {
            getDataReport(1, dayjs().format("YYYY-MM-DD") + "00:00:00", dayjs().format("YYYY-MM-DD HH:mm:ss"))
        }
    };
    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataReport(page - 1, dayjs(form.getFieldValue("date")[0].format("YYYY-MM-DD HH:mm:ss")), dayjs(form.getFieldValue("date")[1].format("YYYY-MM-DD HH:mm:ss")));
    }

    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataReport(value, dayjs(form.getFieldValue("date")[0]).format("YYYY-MM-DD HH:mm:ss"), dayjs(form.getFieldValue("date")[1]).format("YYYY-MM-DD HH:mm:ss"))
    }
    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataReport(page + 1, dayjs(form.getFieldValue("date")[0]).format("YYYY-MM-DD HH:mm:ss"), dayjs(form.getFieldValue("date")[1]).format("YYYY-MM-DD HH:mm:ss"));
    }

    const getDataForFilter = async () => {
        setLoading(true);
        const res = await getListDataShopForFilter();
        setLoading(false);
        if (res && res.status === 1) {
            
            setDataTTS([
                ...res?.data?.ttsData?.reduce((acc, item) => {
                    // Kiểm tra nếu item với tts_id đã tồn tại trong acc
                    if (!acc.some(existingItem => existingItem.tts_id === item.tts_id)) {
                        acc.push({
                            ...item,
                            value: item.tts_id,
                            label: item.tts_name
                        });
                    }
                    return acc;
                }, [])
            ]);

            setDataPrintifyShop([
                ...res?.data?.printifyData?.reduce((acc, item) => {
                    // Kiểm tra nếu item với id đã tồn tại trong acc
                    if (!acc.some(existingItem => existingItem.printify_shop_id === item.printify_shop_id)) {
                        acc.push({
                            ...item,
                            value: item.printify_shop_id,
                            label: item.printify_shop_name
                        });
                    }
                    return acc;
                }, [])
            ]);
        } else {
            setDataTTS([]);
            setDataPrintifyShop([]);
        }
    }

    useEffect(() => {
        getDataForFilter();
    }, []);
    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name={'tts_id'}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn tiktok shop'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={dataTTS}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name={'printify_shop_id'}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn printify shop'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={dataPrintifyShop}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name={'date'}
                            rules={requiredItem}
                        >
                            <RangePicker
                                style={{ height: 40, width: '100%' }}
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>


                    <Col span={2}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
            />
            <FooterTable
                totalRecord={totalRecords}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
            </FooterTable>
            <LoadingBase loading={loading} />

        </div>
    )
}
export default React.memo(ReportTransitionOrder);